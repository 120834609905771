import jwt_decode from "jwt-decode";
import { updateSku, updateUid } from "../store/slices/nfc-slice";
import privilege from "./privilege.js";

const addDate = (dt, amount, dateType) => {
  switch (dateType) {
    case "days":
      return dt.setDate(dt.getDate() + amount) && dt;
    case "weeks":
      return dt.setDate(dt.getDate() + 7 * amount) && dt;
    case "months":
      return dt.setMonth(dt.getMonth() + amount) && dt;
    case "years":
      return dt.setFullYear(dt.getFullYear() + amount) && dt;
  }
};

const updateNFCByToken = (token, dispatch) => {
  const decoded = jwt_decode(token);
  const sku = decoded.sku;
  const uid = decoded.uid;
  dispatch(updateSku(sku));
  dispatch(updateUid(uid));
  return { sku, uid };
};

const mapEmail = (userEmail = "") =>
  userEmail
    .split(/(?:@|\.)+/) // splite by '@' and '.'
    .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
    .join("");

const utils = { updateNFCByToken, addDate, mapEmail, privilege };
export default utils;

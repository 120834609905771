import PropTypes from "prop-types";
import React, { Fragment } from "react";

import { Footer } from "../../components";

const DefaultLayout = ({ children }) => {
  return (
    <Fragment>
      {children}
      <Footer />
    </Fragment>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.any,
};

export default DefaultLayout;

import React from "react";
import { ReactSVG } from "react-svg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Offcanvas = (props) => {

  const userName = useSelector((state) => state.user.userName);
  const userImg = useSelector((state) => state.user.userImg);

  const offcanvasNavigations = document.querySelectorAll(
    ".offcanvas-navigation > li"
  );
  offcanvasNavigations.forEach((single) => {
    single.addEventListener("click", () => {
      props.activeStatus(false);
    });
  });

  return (
    <div className={`offcanvas-menu ${props.show ? "active" : ""}`}>
      <div className="profile-card text-center">
        <div className="profile-card__image space-mb--10">
          <img
            src={userImg}
            className="img-fluid"
            alt=""
          />
        </div>
        <div className="profile-card__content">
          <p className="name">{userName}</p>
        </div>
      </div>
      <div className="offcanvas-navigation-wrapper space-mt--40">
        <ul className="offcanvas-navigation">
          <li>
            <span className="icon">
              <ReactSVG
                src={process.env.PUBLIC_URL + "/assets/img/icons/profile.svg"}
              />
            </span>
            <Link to={process.env.PUBLIC_URL + "/"}>登出</Link>
          </li>
          {/*<li>
              <span className="icon">
                <ReactSVG
                  src={
                    process.env.PUBLIC_URL + "/assets/img/icons/profile-two.svg"
                  }
                />
              </span>
              <Link to={process.env.PUBLIC_URL + "/profile"}>My Profile</Link>
            </li>
            <li>
              <span className="icon">
                <ReactSVG
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/img/icons/notification.svg"
                  }
                />
              </span>
              <Link to={process.env.PUBLIC_URL + "/notification"}>
                Notification
              </Link>
            </li>
            <li>
              <span className="icon">
                <ReactSVG
                  src={process.env.PUBLIC_URL + "/assets/img/icons/list.svg"}
                />
              </span>
              <Link to={process.env.PUBLIC_URL + "/category"}>Categories</Link>
            </li>
            <li>
              <span className="icon">
                <ReactSVG
                  src={process.env.PUBLIC_URL + "/assets/img/icons/product.svg"}
                />
              </span>
              <Link to={process.env.PUBLIC_URL + "/shop"}>All products</Link>
            </li>
            <li>
              <span className="icon">
                <ReactSVG
                  src={
                    process.env.PUBLIC_URL + "/assets/img/icons/cart-two.svg"
                  }
                />
              </span>
              <Link to={process.env.PUBLIC_URL + "/order"}>My Order</Link>
            </li>
            <li>
              <span className="icon">
                <ReactSVG
                  src={
                    process.env.PUBLIC_URL + "/assets/img/icons/cart-three.svg"
                  }
                />
              </span>
              <Link to={process.env.PUBLIC_URL + "/cart"}>Cart</Link>
            </li>
            <li>
              <span className="icon">
                <ReactSVG
                  src={
                    process.env.PUBLIC_URL + "/assets/img/icons/wishlist.svg"
                  }
                />
              </span>
              <Link to={process.env.PUBLIC_URL + "/wishlist"}>Wishlist</Link>
            </li>
            <li>
              <span className="icon">
                <ReactSVG
                  src={
                    process.env.PUBLIC_URL + "/assets/img/icons/gear-two.svg"
                  }
                />
              </span>
              <Link to={process.env.PUBLIC_URL + "/edit-profile"}>
                Settings
              </Link>
            </li>
            <li>
              <span className="icon">
                <ReactSVG
                  src={process.env.PUBLIC_URL + "/assets/img/icons/profile.svg"}
                />
              </span>
              <Link to={process.env.PUBLIC_URL + "/contact"}>Contact Us</Link>
            </li>*/}
        </ul>
      </div>
    </div>
  );
};

export default Offcanvas;

const { createSlice } = require("@reduxjs/toolkit");

const nfcSlice = createSlice({
  name: "nfc",
  initialState: {
    nfcSku: "",
    nfcUid: "",
    nfcProduct: {},
    nfcOwner: {},
    nfcRegisterable: true,
  },
  reducers: {
    updateSku(state, action) {
      state.nfcSku = action.payload;
    },
    updateUid(state, action) {
      state.nfcUid = action.payload;
    },
    nfcLogout(state) {
      state.nfcSku = "";
      state.nfcUid = "";
      state.nfcProduct = {};
      state.nfcOwner = {};
      state.nfcRegisterable = true;
    },
    updateNfcProduct(state, action) {
      state.nfcProduct = action.payload;
    },
    updateNfcOwner(state, action) {
      state.nfcOwner = action.payload;
    },
    updateNfcRegisterable(state, action) {
      state.nfcRegisterable = action.payload;
    },
  },
});

export const {
  updateSku,
  updateUid,
  updateImgUrlMapper,
  nfcLogout,
  updateNfcProduct,
  updateNfcOwner,
  updateNfcRegisterable,
} = nfcSlice.actions;
export default nfcSlice.reducer;

import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import axios from "axios";
import { store } from "./store/store";
import PersistProvider from "./store/providers/persist-provider";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "animate.css";
import "./assets/scss/style.scss";
import { GoogleOAuthProvider } from "@react-oauth/google";

const container = document.getElementById("root");
const root = createRoot(container);
const clientId = "702669630146-5uj9kmig5lgbstqjbgrc53ol33gn9rde.apps.googleusercontent.com"

root.render(
  <Provider store={store}>
    <PersistProvider>
      <GoogleOAuthProvider clientId={clientId}>
        <App />
      </GoogleOAuthProvider>
    </PersistProvider>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

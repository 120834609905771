import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  GiftOutline,
  UserSetOutline,
  HistogramOutline,
  ScanningOutline,
} from "antd-mobile-icons";
import utils from "../../utils/utils.js";
const { privilege } = utils;

const Footer = () => {
  const userManager = useSelector((state) => state.user.userManager);
  const isAdmin = privilege.isAdmin(userManager);
  const isManager = privilege.isManager(userManager);
  const isCoach = privilege.isCoach(userManager);

  return (
    <footer>
      <div className="footer-nav-wrapper footer-nav-wrapper--styleTwo">
        <Link
          to={process.env.PUBLIC_URL + "/history"}
          className="footer-nav-single footer-nav-single--styleTwo"
        >
          <div className="menu-wrapper">
            <GiftOutline fontSize={24} color="var(--adm-color-weak)" />
          </div>
        </Link>
        {(isAdmin || isManager || isCoach) && (
          <Link
            to={process.env.PUBLIC_URL + "/manage"}
            className="footer-nav-single footer-nav-single--styleTwo"
          >
            <div className="menu-wrapper">
              <HistogramOutline fontSize={24} color="var(--adm-color-weak)" />
            </div>
          </Link>
        )}
        <Link
          to={process.env.PUBLIC_URL + "/home"}
          className="footer-nav-single footer-nav-single--styleTwo"
        >
          <div className="menu-wrapper">
            <ScanningOutline fontSize={24} color="var(--adm-color-weak)" />
          </div>
        </Link>
        <Link
          to={process.env.PUBLIC_URL + "/setting"}
          className="footer-nav-single footer-nav-single--styleTwo"
        >
          <div className="menu-wrapper">
            <UserSetOutline fontSize={24} color="var(--adm-color-weak)" />
          </div>
        </Link>
      </div>
    </footer>
  );
};

export default Footer;

const { createSlice } = require("@reduxjs/toolkit");

const timerSlice = createSlice({
  name: "timer",
  initialState: {},
  reducers: {
    updateTimer(state, action) {
      state.ttl = action.payload;
    },
  },
});

export const { updateTimer } = timerSlice.actions;
export default timerSlice.reducer;

const { createSlice } = require("@reduxjs/toolkit");

const cacheSlice = createSlice({
  name: "cache",
  initialState: {},
  reducers: {
    updateCache(state, action) {
      state[action.payload.key] = {
        value: action.payload.value,
        ttl: action.payload.ttl,
      };
    },
  },
});

export const { updateCache } = cacheSlice.actions;
export default cacheSlice.reducer;

import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
import { ConfigProvider } from "antd";

import { DefaultLayout } from "./layouts";
import { Preloader } from "./components";
import ScrollToTop from "./helpers/scroll-top";
import { useSelector } from "react-redux";
import utils from "./utils/utils.js";
const { privilege } = utils;

const NotFound = lazy(() => import("./pages/NotFound"));
const Home = lazy(() => import("./pages/Home"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const History = lazy(() => import("./pages/History"));
const Manager = lazy(() => import("./pages/Manager"));
const Discount = lazy(() => import("./pages/Discount"));
const Setting = lazy(() => import("./pages/Setting"));
const NFC = lazy(() => import("./pages/NFC"));
const Login = lazy(() => import("./pages/Login"));
const Edit = lazy(() => import("./pages/Edit"));
const Membership = lazy(() => import("./pages/Membership"));
const AR = lazy(() => import("./pages/AR"));
const Dashboard = lazy(() => import("./pages/Dashboard"));

function App() {
  const isLogin = useSelector((state) => state.user.isLogin);
  const userManager = useSelector((state) => state.user.userManager);
  const isManager = privilege.isManager(userManager);
  const isAdmin = privilege.isAdmin(userManager);
  const isCoach = privilege.isCoach(userManager);

  return (
    <Router>
      <ConfigProvider
        theme={{
          token: {
            // Seed Token
            colorPrimary: "#6a7e91",
          },
          components: {
            Table: {
              // borderColor: "#D3D3D3",
              cellFontSize: 12,
              // headerBg: "#525252",
              // headerBorderRadius: 0,
              // headerColor: "white",
              // cellPaddingBlock: 5,
              cellPaddingInline: 5,
            },
          },
        }}
      >
        <ScrollToTop>
          <Suspense fallback={<Preloader />}>
            <Routes>
              <Route
                path={process.env.PUBLIC_URL + "/dashboard"}
                element={
                  isLogin && isAdmin ? (
                    <Dashboard />
                  ) : (
                    <Navigate to="/nfc" replace />
                  )
                }
              />
              <Route
                path={process.env.PUBLIC_URL + "/privacy-policy"}
                element={<PrivacyPolicy />}
              />
              <Route path={process.env.PUBLIC_URL + "/nfc"} element={<NFC />} />
              <Route
                path={process.env.PUBLIC_URL + "/login"}
                element={<Login />}
              />

              <Route
                path={process.env.PUBLIC_URL + "/edit"}
                element={isLogin ? <Edit /> : <Navigate to="/nfc" replace />}
              />

              <Route
                path={process.env.PUBLIC_URL + "/membership"}
                element={<Membership />}
              />

              <Route
                path={process.env.PUBLIC_URL + "/ar"}
                element={
                  isLogin && isAdmin ? <AR /> : <Navigate to="/nfc" replace />
                }
              />

              <Route
                element={
                  <DefaultLayout>
                    {" "}
                    <Outlet />{" "}
                  </DefaultLayout>
                }
              >
                <Route path={process.env.PUBLIC_URL + "/"} element={<Home />} />
                <Route
                  path={process.env.PUBLIC_URL + "/home"}
                  element={isLogin ? <Home /> : <Navigate to="/nfc" replace />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/history"}
                  element={
                    isLogin ? <History /> : <Navigate to="/nfc" replace />
                  }
                />
                <Route
                  path={process.env.PUBLIC_URL + "/setting"}
                  element={
                    isLogin ? <Setting /> : <Navigate to="/nfc" replace />
                  }
                />
                <Route
                  path={process.env.PUBLIC_URL + "/manage"}
                  element={
                    isLogin && (isAdmin || isManager || isCoach) ? (
                      <Manager />
                    ) : (
                      <Navigate to="/nfc" replace />
                    )
                  }
                />
                <Route
                  path={process.env.PUBLIC_URL + "/discount"}
                  element={
                    isLogin && (isManager || isCoach) ? (
                      <Discount />
                    ) : (
                      <Navigate to="/nfc" replace />
                    )
                  }
                />
                <Route path="*" element={<NotFound />} />
              </Route>
            </Routes>
          </Suspense>
        </ScrollToTop>
      </ConfigProvider>
    </Router>
  );
}

export default App;

const { createSlice } = require("@reduxjs/toolkit");

const userSlice = createSlice({
  name: "user",
  initialState: {
    isLogin: false,
    userManager: {},
    userEmail: "",
    userName: "",
    userImg: "",
    userSetting: {},
    userHistories: null,
    userRegisterable: false,
    userMembership: 0,
    userIntro: "",
  },
  reducers: {
    userLogin(state, action) {
      state.isLogin = true;
      state.userManager = action.payload.manager;
      state.userEmail = action.payload.userEmail;
      state.userName = action.payload.userName;
      state.userImg = action.payload.userImg;
    },
    userLogout(state) {
      state.isLogin = false;
      state.userManager = {};
      state.userEmail = "";
      state.userName = "";
      state.userImg = "";
      state.userSetting = {};
      state.userHistory = null;
    },
    updateName(state, action) {
      state.userName = action.payload;
    },
    updateIntro(state, action) {
      state.userIntro = action.payload;
    },
    updateSetting(state, action) {
      state.userSetting = action.payload;
    },
    updateHistory(state, action) {
      state.userHistory = action.payload;
    },
    updateUserRegisterable(state, action) {
      state.userRegisterable = action.payload;
    },
    updateUserMembership(state, action) {
      state.userMembership = action.payload;
    },
    updateManager(state, action) {
      state.userManager = action.payload;
    },
  },
});

export const {
  userLogin,
  userLogout,
  updateName,
  updateIntro,
  updateSetting,
  updateHistory,
  updateUserRegisterable,
  updateUserMembership,
  updateManager,
} = userSlice.actions;
export default userSlice.reducer;
